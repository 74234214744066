import React, {useEffect, useState} from 'react'
import moment from 'moment'
import 'moment/locale/pl';
import 'react-big-scheduler/lib/css/style.css'
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'
import withDndContext from './withDndContext'
import {api} from "../../api";
import {
  Box,
  Button,
  Checkbox,
  Dialog, DialogContent, DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, TextField, Grid, DialogActions, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from "@material-ui/core/styles";
import {Add, Close} from "@material-ui/icons";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl'
import {format} from "date-fns";

// const BASE_URL = 'https://spolexcrm.crmexpert.co'
const BASE_URL = 'https://wojtek.crmexpert.co'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 160,
    textAlign: 'left',
  },
  textField: {
    width: '100%',
    background: '#FFF'
  },
  listItem: {
    lineHeight: 1.5,
    margin: 0
  }
}));

const STATUSES = [
  'Wstępny',
  'Potwierdzony',
  'Rezerwacja',
  'Zamknięty',
  'Przesunięty'
]

const TYPES = [
  'Kontraktowy',
  'Gwarancyjny',
  'Usługa serwisowa',
  'FAT',
  'Szkolenie',
  'Biuro',
  'Dyżur',
  'Inny'
]

let staticResources = [
  {
    id: 'r0',
    name: 'Wyjazdy',
    groupOnly: true
  },
  {
    id: 'r1',
    name: 'Urlopy',
    groupOnly: true
  },
];

const MyScheduler = () => {
  const [scheduler, setScheduler] = useState(null)
  const [hasChanged, setHasChanged] = useState(false)
  const [owners, setOwners] = useState({})
  const [ownersToFilter, setOwnersToFilter] = useState([])
  const [statusesToFilter, setStatusesToFilter] = useState([])
  const [typesToFilter, setTypesToFilter] = useState([])
  const [today, setToday] = useState(new Date())

  const [departureToEdit, setDepartureToEdit] = useState({})

  const [contractorInputValue, setContractorInputValue] = useState('')
  const [contractorOptions, setContractorOptions] = useState([])
  const [contractorLoading, setContractorLoading] = useState(false)

  const [contactInputValue, setContactInputValue] = useState('')
  const [contactOptions, setContactOptions] = useState([])
  const [contactLoading, setContactLoading] = useState(false)

  const [projectInputValue, setProjectInputValue] = useState('')
  const [projectOptions, setProjectOptions] = useState([])
  const [projectLoading, setProjectLoading] = useState(false)

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const classes = useStyles();

  const isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;

    let dayOfWeek = localeMoment(time).weekday();

    if (dayOfWeek === 5 || dayOfWeek === 6)
      return true;
  }

  const getContractors = (searchValue) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
    }

    return api.get("/Accounts/Autocomplete", {
      headers,
      params: {
        term: searchValue
      }
    })
  }

  const getContacts = (searchValue) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
    }

    return api.get("/Contacts/Autocomplete", {
      headers,
      params: {
        term: searchValue
      }
    })
  }

  const getProjects = (searchValue) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
    }

    return api.get("/Project/Autocomplete", {
      headers,
      params: {
        term: searchValue
      }
    })
  }


  useEffect(() => {
    if (contractorInputValue) {
      setContractorLoading(true)
      const delayDebounceFn = setTimeout(() => {
        getContractors(contractorInputValue).then(res => {
          if (res.data?.result) {
            setContractorOptions(res.data.result)
          }
          setContractorLoading(false)
        })
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    } else {
      setContractorLoading(false)
    }
    }, [contractorInputValue])

  useEffect(() => {
    if (contactInputValue) {
      setContactLoading(true)
      const delayDebounceFn = setTimeout(() => {
        // Send Axios request here
        getContacts(contactInputValue).then(res => {
          if (res.data?.result) {
            setContactOptions(res.data.result)
          }
          setContactLoading(false)
        })
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    } else {
      setContactLoading(false)
    }
  }, [contactInputValue])

  useEffect(() => {
    if (projectInputValue) {
      setProjectLoading(true)
      const delayDebounceFn = setTimeout(() => {
        // Send Axios request here
        getProjects(projectInputValue).then(res => {
          if (res.data?.result) {
            setProjectOptions(res.data.result)
          }
          setProjectLoading(false)
        })
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    } else {
      setProjectLoading(false)
    }
  }, [projectInputValue])

  const editDeparture = (id, departure) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
    }

    return api.put(`/Departures/Record/${id}`,{
      ...departure
    },{
      headers,
    })
  }

  const createDeparture = (departure) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
    }

    return api.post(`/Departures/Record`,{
      ...departure
    },{
      headers,
    })
  }

  const departureIsValid = () => {
    return departureToEdit.name && departureToEdit.type && departureToEdit.contractor && departureToEdit.serviceman && departureToEdit.status && departureToEdit.startDate && departureToEdit.endDate
  }

  const getResources = (startDate, endDate) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
      'from': startDate,
      'to': endDate,
    }

    if (ownersToFilter.length > 0) {
      headers.owners = JSON.stringify(ownersToFilter.map(val => val))
    }
    if (statusesToFilter.length > 0) {
      headers.statuses = JSON.stringify(statusesToFilter.map(val => encodeURI(val)))
    }
    if (typesToFilter.length > 0) {
      headers.types = JSON.stringify(typesToFilter.map(val => encodeURI(val)))
    }

    return api.get("/Departures/CalendarRecordsList", {
      headers,
    })
  }

  const getVacations = (startDate, endDate) => {
    const headers = {
      "Content-Type": "application/json",
      'X-TOKEN': localStorage.getItem('token'),
      'from': startDate,
      'to': endDate,
      statuses: JSON.stringify(['Accepted']),
    }

    if (ownersToFilter.length > 0) {
      headers.owners = JSON.stringify(ownersToFilter.map(val => val))
    }

    return api.get("/OSSTimeControl/CalendarRecordsList", {
      headers,
    })
  }

  const getOwners = () => {
    return api.get("/Users/OwnersList", {
      headers: {
        "Content-Type": "application/json",
        'X-TOKEN': localStorage.getItem('token'),
      },
    })
  }

  const getData = (startDate, endDate) => {
    return Promise.all([
      getResources(startDate, endDate),
      getVacations(startDate, endDate),
      getOwners()
    ])
  }

  const createDepartureResources = (departures = []) => {
    return addParentIdToResources(departures, 'r0')
  }

  const createVacationsResources = (owners, vacations = []) => {
    const ownersFromVacations = vacations.map(vac => `${vac.ownerId}`)
    const resources = Object.keys(owners).map(id => ({id: id, name: owners[id], type: 'vacation', item: 'vacation', parentId: 'r1'}))

    return resources.filter(res => ownersFromVacations.includes(res.id))
  }

  const createDeparturesEvents = (departures = []) => {
    return departures.map((item, index) => {
      let owners = []

      if (item.serviceman?.name) {
        owners.push(item.serviceman.name)
      }
      item.owners.forEach(owner => {
        owners.push(owner.name)
      })

      const title = owners.join(', ')
      // const title = [item.serviceman, [item.owners.map(owner => owner.name)].join(', ')]

      return ({
        id: item.id,
        resourceId: item.id,
        start: `${item.startDate} 00:00:00`,
        end: `${item.endDate} 23:59:59`,
        title: `${title} (${item.status})`,
        status: item.status,
        bgColor: getBackgroundColorByStatus(item.status),
        ...item
      })
    })
  }

  const createVacationsEvents = (vacations = []) => {
    return vacations.map((item, index) => ({
      id: item.id,
      resourceId: `${item.ownerId}`,
      start: `${item.startDate} 00:00:00`,
      end: `${item.endDate} 23:59:59`,
      title: item.owner,
      movable: false,
      resizable: false,
      status: item.status,
      bgColor: getBackgroundColorByStatus(item.status),
      type: 'vacation',
      // type: item.status === 'Zamknięty' ? 3 : 1
    }))
  }

  const transformData = (departures, vacations, owners) => {

    const departuresResources = createDepartureResources(departures)
    const vacationsResources = createVacationsResources(owners, vacations)
    // const vacationsResources = []

    const departuresEvents = createDeparturesEvents(departures)
    const vacationsEvents = createVacationsEvents(vacations)

    const resources = [
      ...staticResources,
      ...sortByDateAscending(departuresResources, 'startDate'),
      ...vacationsResources
    ]
    const events = sortByDateAscending([...departuresEvents, ...vacationsEvents],'start')

    return {
      resources,
      events
    }
  }

  const sortByDateAscending = (array = [], startKey) => {
    return array.sort(function (a, b) {
      return new Date(a[startKey]) - new Date(b[startKey])
    })
  }

  const addParentIdToResources = (array = [], id) => {
    return array.map(item => ({...item, parentId: id}))
  }


  useEffect(() => {
    getOwners().then(res => {
      setOwners(res.data.result)
    })

    let schedulerData = new SchedulerData(new Date(), ViewTypes.Week, false, false, {
      crossResourceMove: false,
      eventItemPopoverEnabled: false,
      // creatable: false,
      resourceName: 'Harmonogram',
      // dayResourceTableWidth: 500,
      // weekResourceTableWidth: 500,
      // monthResourceTableWidth: 500,
      // yearResourceTableWidth: 500,
      // quarterResourceTableWidth: 500,
      views: [
        {viewName: 'Tydzień', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
        {viewName: 'Miesiąc', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false},
        {viewName: 'Kwartał', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false},
        {viewName: 'Rok', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false},
      ],
    }, {
      isNonWorkingTimeFunc: isNonWorkingTime
    });
    schedulerData.localeMoment.locale('pl');

    getData(schedulerData.startDate, schedulerData.endDate).then(
      ([departures, vacations, owners]) => {

        const { resources, events } = transformData(departures.data.result, vacations.data.result, owners.data.result)

        schedulerData.setResources(resources);
        schedulerData.setEvents(events);
        setScheduler(schedulerData)
      }
    )

//set events here or later,
//the event array should be sorted in ascending order by event.start property, otherwise there will be some rendering errors

  }, []);

  const updateResources = (startDate, endDate) => {
    getData(startDate, endDate).then(
      ([departures, vacations, owners]) => {
        const { resources, events } = transformData(departures.data.result, vacations.data.result, owners.data.result)

        scheduler.setResources(resources);
        scheduler.setEvents(events);
        setHasChanged(!hasChanged)
        setScheduler(scheduler)
      }
    )
  }

  useEffect(() => {
    if (scheduler) {
      updateResources(scheduler.startDate, scheduler.endDate)
    }
  }, [scheduler?.startDate, scheduler?.selectDate, ownersToFilter, statusesToFilter, typesToFilter, today, scheduler?.viewType])

  const nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    }
    else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    if (isCurrentDate) {
      style.backgroundColor = '#118dea';
      style.color = 'white';
    }

    const dateMoment = moment(item.time)

    const dayOfWeek = dateMoment.format('dd')
    const isoDay =  dateMoment.format('d')
    const plDateFormat =  dateMoment.format('DD.MM')
    const week =  dateMoment.format('W')

    const headerText = `${dayOfWeek} ${plDateFormat}`

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {
          formattedDateItems.map((formattedItem, index) => {
            return (
              <div
                // style={{paddingBottom: '10px'}}
              >
                <div>W{week}</div>
                <div>{headerText}</div>
              </div>
              // <div key={index}
              //      dangerouslySetInnerHTML={{__html: headerText}}/>
            )
          })
        }
      </th>
    );
  }

  const prevClick = (schedulerData)=> {
    schedulerData.prev();
    // schedulerData.setEvents(events);
    setScheduler(schedulerData)
    setHasChanged(!hasChanged)

  }

  const nextClick = (schedulerData)=> {
    schedulerData.next();
    // schedulerData.setEvents(events);
    setScheduler(schedulerData)
    setHasChanged(!hasChanged)


  }

  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    // schedulerData.setEvents(events);
    setScheduler(schedulerData)
    setHasChanged(!hasChanged)

  }

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    // schedulerData.setEvents(events);
    setScheduler(schedulerData)
    // setHasChanged(!hasChanged)

    updateResources(schedulerData.startDate, schedulerData.endDate)

  }

  // const eventClicked = (schedulerData, event) => {
  //   if (event.type === 'vacation') {
  //      return
  //   }
  //
  //   const departure = {
  //     ...event,
  //     startDate: event.start,
  //     endDate: event.end,
  //     serviceman: `${event.serviceman.id}`
  //   }
  //   setDepartureToEdit(departure)
  //   setOpenEditDialog(true)
  //   setIsEditMode(true)
  //   // alert(`Haha!!! You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
  // };

  const ops1 = (schedulerData, event) => {
    alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  const ops2 = (schedulerData, event) => {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  const slotClickedFunc = (schedulerData, slot) => {
    if (!schedulerData.isEventPerspective) {
      // if (slot.parentId === 'r0') {
      //   window.open(`${BASE_URL}/index.php?module=Departures&view=Detail&record=${slot.slotId}`)
      // }
      const departureToSet = schedulerData.resources.find(resource => resource.id === slot.slotId)

      if (departureToSet) {
        const departure = {
          ...departureToSet,
          serviceman: `${departureToSet.serviceman.id}`
        }
        setDepartureToEdit(departure)
        setOpenEditDialog(true)
        setIsEditMode(true)
      }
    }
    // alert(`You just clicked a ${schedulerData.isEventPerspective ? 'task':'resource'}.{id: ${slot.slotId}, name: ${slot.slotName}}`);
  }

  const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (owners[slotId] !== undefined) {
      return
    }
    // if(window.confirm(`Czy chcesz stworzyć nowy wyjazd? Termin rozpoczęcia: ${start}, termin zakończenia: ${end}`)){
        const departure = {
          startDate: start,
          endDate: end
        }
        setDepartureToEdit(departure)
        setOpenEditDialog(true)
    // }
  }

  const updateEventStart = (schedulerData, event, newStart) => {
    if(window.confirm(`Czy chcesz zmienić datę początkową na ${format(new Date(newStart), 'dd.MM.yyyy')}?`)) {
      schedulerData.updateEventStart(event, newStart);
      setScheduler(schedulerData)
      setHasChanged(!hasChanged)

      editDeparture(event.id, {
        startDate: format(new Date(newStart), 'yyyy-MM-dd'),
      })
    } else {
      setHasChanged(!hasChanged)
    }
  }

  const updateEventEnd = (schedulerData, event, newEnd) => {
    if(window.confirm(`Czy chcesz zmienić datę końcową na ${format(new Date(newEnd), 'dd.MM.yyyy')}?`)) {
      schedulerData.updateEventEnd(event, newEnd);
      setScheduler(schedulerData)
      setHasChanged(!hasChanged)

      editDeparture(event.id, {
        endDate: format(new Date(newEnd), 'yyyy-MM-dd'),
      })
    } else {
      setHasChanged(!hasChanged)
    }
  }

  const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if(window.confirm(`Czy chcesz zmienić daty wyjazdu na: Początkowa: ${format(new Date(start), 'dd.MM.yyyy')}, końcowa: ${format(new Date(end), 'dd.MM.yyyy')}?`)) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      setScheduler(schedulerData)
      setHasChanged(!hasChanged)

      editDeparture(event.id, {
        startDate: format(new Date(start), 'yyyy-MM-dd'),
        endDate: format(new Date(end), 'yyyy-MM-dd'),
      })
    }
  }

  const onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if(schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      // schedulerData.setEvents(events);
      setScheduler(schedulerData)
      setHasChanged(!hasChanged)



      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  }

  const onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if(schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      // schedulerData.setEvents(events);
      setScheduler(schedulerData)
      setHasChanged(!hasChanged)

      schedulerContent.scrollLeft = 10;
    }
  }

  const onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollTop');
  }

  const onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollBottom');
  }

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    setScheduler(schedulerData)
    setHasChanged(!hasChanged)

  }

  const getBorderColorByStatus = (status) => {
    switch (status) {
      case 'Wstępny':
        return 'rgba(253,165,15,1)'
      case 'Zamknięty':
        return 'rgba(245,60,43,1)'
      case 'Potwierdzony':
        return 'rgba(100,163,56,1)'
      case 'Rezerwacja':
        return 'rgba(0,139,236,1)'
      case 'Przesunięty':
        return 'rgba(253,123,185,1)'
      case 'Zaakceptowano':
        return 'rgba(188,99,216,1)'
      default:
        return 'rgba(0,139,236,1)'
    }
  }

  const getBackgroundColorByStatus = (status) => {
    switch (status) {
      case 'Wstępny':
        return 'rgba(253,165,15,0.5)'
      case 'Zamknięty':
        return 'rgba(245,60,43,0.5)'
      case 'Potwierdzony':
        return 'rgba(100,163,56,0.5)'
      case 'Rezerwacja':
        return 'rgba(0,139,236,0.5)'
      case 'Przesunięty':
        return 'rgba(253,123,185,0.5)'
      case 'Zaakceptowano':
        return 'rgba(188,99,216,0.5)'
      default:
        return 'rgba(0,139,236,0.5)'
    }
  }

  const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let borderWidth = isStart ? '4' : '0';
    const backgroundColor = getBackgroundColorByStatus(event.status), borderColor = getBorderColorByStatus(event.status);
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    // if(!!event.type){
    //   borderColor = event.type == 1 ? 'rgba(0,139,236,1)' : (event.type == 2 ? 'rgba(245,60,43,1)' : '#999');
    //   backgroundColor = event.type == 1 ? '#80C5F6' : (event.type == 2 ? '#FA9E95' : '#D9D9D9');
    // }
    let divStyle = { borderLeft: `${borderWidth}px solid ${borderColor}`, backgroundColor: backgroundColor, height: mustBeHeight };
    if(!!agendaMaxEventWidth)
      divStyle = {...divStyle, maxWidth: agendaMaxEventWidth};

    return <div key={event.id} className={mustAddCssClass} style={divStyle}>
      <span style={{marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
    </div>;
  }

  let leftCustomHeader = (
    <div>
      {/*<span style={{fontWeight: 'bold', color: 'red'}}>Put your content here</span>*/}
      <Button
        onClick={() => {
          let newScheduler = scheduler
          newScheduler.setDate(new Date())

          setScheduler(newScheduler)
          setToday(new Date())
        }}
        className='today-button'
        disableRipple
        style={{height: '32px', borderRadius: '4px', borderColor: '#d9d9d9', lineHeight: 1.75, background: 'transparent'}}
        variant={'outlined'}
      >
        <span className='today-button-label'>Dzisiaj</span>
      </Button>
    </div>
  );

  return (
    <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
      <div style={{textAlign: 'left', background: '#fff', minHeight: '100vh', position: 'relative', top: '-20px', marginTop: '0', paddingTop: '20px'}}>
        <Dialog fullWidth maxWidth='lg' open={openEditDialog} onClose={() => {
          setOpenEditDialog(false)
          setIsEditMode(false)
          setDepartureToEdit({})
        }}>
          <DialogTitle>
            {isEditMode && 'Edytuj wyjazd'}
            {!isEditMode && 'Nowy wyjazd'}
            {isEditMode && departureToEdit &&
              <div>
                <a style={{fontSize: '14px'}}
                   href={`${BASE_URL}/index.php?module=Departures&view=Detail&record=${departureToEdit.id}`}
                   target='_blank'>Pokaż w CRM</a>
              </div>
            }
          </DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, name: e.target.value})}
                      value={departureToEdit.name}
                      required
                      label='Nazwa wyjazdu'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      fullWidth
                      noOptionsText='Brak wyników'
                      loadingText='Ładowanie'
                      onChange={(e, newValue) => setDepartureToEdit({...departureToEdit, contractor: newValue})}
                      onInputChange={(e, newValue) => {
                        setContractorInputValue(newValue)
                      }}
                      value={departureToEdit.contractor}
                      inputValue={contractorInputValue}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      getOptionLabel={(option) => option.name}
                      options={contractorOptions}
                      loading={contractorLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kontrahent *"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {contractorLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel style={{margin: '-4px 11px 11px 11px'}}>Rodzaj wyjazdu *</InputLabel>
                      <Select
                        labelWidth={110}
                        required
                        variant='outlined'
                        value={departureToEdit.type}
                        onChange={e => setDepartureToEdit({...departureToEdit, type: e.target.value})}
                        fullWidth
                      >
                        {
                          TYPES.map(type => {
                            return (
                              <MenuItem key={type} value={type}>
                                <ListItemText className={classes.listItem} primary={type} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      fullWidth
                      noOptionsText='Brak wyników'
                      loadingText='Ładowanie'
                      onChange={(e, newValue) => setDepartureToEdit({...departureToEdit, contact: newValue})}
                      onInputChange={(e, newValue) => {
                        setContactInputValue(newValue)
                      }}
                      value={departureToEdit.contact}
                      inputValue={contactInputValue}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      getOptionLabel={(option) => option.name}
                      options={contactOptions}
                      loading={contactLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Osoba kontaktowa"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {contactLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      fullWidth
                      noOptionsText='Brak wyników'
                      loadingText='Ładowanie'
                      onChange={(e, newValue) => setDepartureToEdit({...departureToEdit, project: newValue})}
                      onInputChange={(e, newValue) => {
                        setProjectInputValue(newValue)
                      }}
                      value={departureToEdit.project}
                      inputValue={projectInputValue}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      getOptionLabel={(option) => option.name}
                      options={projectOptions}
                      loading={projectLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Projekt"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {projectLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel style={{margin: '-4px 11px 11px 11px'}}>Serwisant *</InputLabel>
                      <Select
                        value={departureToEdit.serviceman}
                        labelWidth={85}
                        required
                        onChange={(e, val) => {
                          setDepartureToEdit({...departureToEdit, serviceman: e.target.value})
                        }}
                        variant='outlined'
                        fullWidth
                        // renderValue={(selected) => selected.map(item => owners[parseInt(item)]).join(', ')}
                      >
                        {
                          Object.keys(owners).map(owner => ({id: owner, name: owners[owner]})).map(item => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                <ListItemText className={classes.listItem} primary={item.name} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel style={{margin: '-4px 11px 11px 11px'}}>Osoby zaangażowane</InputLabel>
                      <Select
                        value={departureToEdit.owners || []}
                        labelWidth={170}
                        multiple
                        required
                        onChange={(e) => {
                          setDepartureToEdit({...departureToEdit, owners: e.target.value})
                        }}
                        variant='outlined'
                        fullWidth
                        renderValue={(selected) => selected.map(item => {
                          if (item.id) {
                            return owners[parseInt(item.id)]
                          } else {
                            return owners[parseInt(item)]
                          }
                        }).join(', ')}
                      >
                        {
                          Object.keys(owners).map(owner => ({id: owner, name: owners[owner]})).map(item => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                <ListItemText className={classes.listItem} primary={item.name} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel style={{margin: '-4px 11px 11px 11px'}}>Status *</InputLabel>
                      <Select
                        labelWidth={65}
                        required
                        variant='outlined'
                        value={departureToEdit.status}
                        onChange={e => setDepartureToEdit({...departureToEdit, status: e.target.value})}
                        fullWidth
                      >
                        {
                          STATUSES.map(status => {
                            return (
                              <MenuItem key={status} value={status}>
                                <ListItemText className={classes.listItem} primary={status} />
                              </MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mt={-2}>
                    <DatePicker
                      disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant='outlined'
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data rozpoczęcia *"
                      value={departureToEdit.startDate || null}
                      onChange={(val) => setDepartureToEdit({...departureToEdit, startDate: val})}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Box>
                  <Box mt={-1}>
                    <DatePicker
                      disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant='outlined'
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data zakończenia *"
                      value={departureToEdit.endDate || null}
                      minDate={departureToEdit.startDate}
                      onChange={(val) => setDepartureToEdit({...departureToEdit, endDate: val})}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, street: e.target.value})}
                      value={departureToEdit.street}
                      label='Ulica'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, buildingNumber: e.target.value})}
                      value={departureToEdit.buildingNumber}
                      label='Numer budynku'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, flatNumber: e.target.value})}
                      value={departureToEdit.flatNumber}
                      label='Numer lokalu'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, postalCode: e.target.value})}
                      value={departureToEdit.postalCode}
                      label='Kod pocztowy'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, city: e.target.value})}
                      value={departureToEdit.city}
                      label='Miejscowość'
                      labelWidth={95}
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      onChange={e => setDepartureToEdit({...departureToEdit, country: e.target.value})}
                      value={departureToEdit.country}
                      label='Kraj'
                      labelWidth={95}
                    />
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEditDialog(false)
                setIsEditMode(false)
                setDepartureToEdit({})
              }}
              color="secondary"
            >
              Anuluj
            </Button>
            <Button
              disabled={!departureIsValid()}
              onClick={() => {

                const isValid = departureIsValid()

                if (!isValid) {
                  return
                }

                const departureTransformed = {
                  name: departureToEdit.name,
                  type: departureToEdit.type,
                  status: departureToEdit.status,
                  owners: departureToEdit.owners,
                  serviceman: departureToEdit.serviceman,
                  startDate: format(new Date(departureToEdit.startDate), 'yyyy-MM-dd'),
                  endDate: format(new Date(departureToEdit.endDate), 'yyyy-MM-dd'),
                  contactId: departureToEdit.contact?.id || null,
                  projectId: departureToEdit.project?.id || null,
                  contractorId: departureToEdit.contractor?.id || null,
                  street: departureToEdit.street,
                  buildingNumber: departureToEdit.buildingNumber,
                  flatNumber: departureToEdit.flatNumber,
                  postalCode: departureToEdit.postalCode,
                  city: departureToEdit.city,
                  country: departureToEdit.country,
                }
                if (isEditMode) {
                  editDeparture(departureToEdit.id, departureTransformed).then(() =>
                    updateResources(scheduler.startDate, scheduler.endDate)
                  )
                } else {
                  createDeparture(departureTransformed).then(() =>
                    updateResources(scheduler.startDate, scheduler.endDate)
                  )
                }
                setOpenEditDialog(false)
                setIsEditMode(false)
                setDepartureToEdit({})
              }}
              color="primary"
            >
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
        <div
          style={{
            background: '#fff',
            borderBottom: '1px solid rgb(118,118,118)',
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 999,
            boxSizing: 'border-box',
            padding: '5px 15px',
            textAlign: 'left'
          }}
        >
          <Box display='inline-block'>
            <FormControl>
              <Button
                onClick={() => setOpenEditDialog(!openEditDialog)}
                style={{margin: '8px 20px 0 0', height: '40px', borderColor: '#d9d9d9', borderRadius: '4px'}}
                startIcon={<Add />}
                color='primary'
                variant='contained'
              >
                Nowy wyjazd
              </Button>
            </FormControl>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel style={{lineHeight: 0}}>Oddelegowani</InputLabel>
              <Select
                value={ownersToFilter}
                onChange={(e) => setOwnersToFilter(e.target.value)}
                fullWidth
                multiple
                style={{height: '40px', width: '100%'}}
                renderValue={(selected) => selected.map(item => owners[parseInt(item)]).join(', ')}
              >
                {
                  Object.keys(owners).map(owner => ({id: owner, value: owners[owner]})).map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={ownersToFilter.indexOf(item.id) > -1} />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <IconButton onClick={() => setOwnersToFilter([])} style={{marginTop: '5px'}}>
              <Close />
            </IconButton>
          </Box>
          <Box display='inline-block'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel style={{lineHeight: 0}}>Status</InputLabel>
              <Select
                value={statusesToFilter}
                onChange={(e) => setStatusesToFilter(e.target.value)}
                fullWidth
                multiple
                style={{height: '40px', width: '100%'}}
                renderValue={(selected) => selected.join(', ')}
              >
                {
                  STATUSES.map(status => {
                    return (
                      <MenuItem key={status} value={status}>
                        <Checkbox checked={statusesToFilter.indexOf(status) > -1} />
                        <ListItemText className={classes.listItem} primary={status} />
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <IconButton onClick={() => setStatusesToFilter([])} style={{marginTop: '5px'}}>
              <Close />
            </IconButton>
          </Box>
          <Box display='inline-block'>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel style={{lineHeight: 0}}>Rodzaj</InputLabel>
              <Select
                value={typesToFilter}
                onChange={(e) => setTypesToFilter(e.target.value)}
                fullWidth
                multiple
                style={{height: '40px', width: '100%'}}
                renderValue={(selected) => selected.join(', ')}
              >
                {
                  TYPES.map(status => {
                    return (
                      <MenuItem key={status} value={status}>
                        <Checkbox checked={typesToFilter.indexOf(status) > -1} />
                        <ListItemText primary={status} />
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <IconButton onClick={() => setTypesToFilter([])} style={{marginTop: '5px'}}>
              <Close />
            </IconButton>
          </Box>
          <Box textAlign='left' display='inline-block' position='absolute' top={0} right={0} ml={2}>
        <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(253,165,15,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Wstępny</span>
        </span>
            <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(245,60,43,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Zamknięty</span>
        </span>
            <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(100,163,56,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Potwierdzony</span>
        </span>
            <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(0,139,236,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Rezerwacja</span>
        </span>
            <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(253,123,185,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Przesunięty</span>
        </span>
            <span style={{fontSize: '12px', marginRight: '15px'}}>
          <div style={{display: 'inline-block', border: '5px solid rgba(188,99,216,1)', borderRadius: '50%'}}/>
          <span style={{marginLeft: '5px'}}>Urlop</span>
        </span>
          </Box>

        </div>
        <div>
          {scheduler && <Scheduler
            schedulerData={scheduler}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            // eventItemClick={eventClicked}
            viewEventClick={ops1}
            viewEventText="Ops 1"
            viewEvent2Text="Ops 2"
            viewEvent2Click={ops2}
            updateEventStart={updateEventStart}
            updateEventEnd={updateEventEnd}
            moveEvent={moveEvent}
            newEvent={newEvent}
            onScrollLeft={onScrollLeft}
            onScrollRight={onScrollRight}
            onScrollTop={onScrollTop}
            onScrollBottom={onScrollBottom}
            toggleExpandFunc={toggleExpandFunc}
            nonAgendaCellHeaderTemplateResolver={nonAgendaCellHeaderTemplateResolver}
            eventItemTemplateResolver={eventItemTemplateResolver}
            slotClickedFunc={slotClickedFunc}
            leftCustomHeader={leftCustomHeader}
          />}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )

}

export default withDndContext(MyScheduler)
