import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Login from './features/User/Login';
import Dashboard from './features/User/Dashboard';
import {PrivateRoute} from './helpers/PrivateRoute';
import {Typography} from "@material-ui/core";
import Scheduler from "./features/Scheduler/scheduler.js";

function App() {

  return (
    <div className="App">
      {/*<Typography variant='body1'>*/}
        <Router>
          <Switch>
            <Route exact component={Login} path={`/login`} />
            <Route exact component={Scheduler} path={`/planowanie`} />
            <PrivateRoute component={Dashboard} path={`/`} />
          </Switch>
        </Router>
      {/*</Typography>*/}
    </div>
  );
}

export default App;
